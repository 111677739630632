import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from './loader_payment.json'

class LoaderAnimation extends Component {
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        };

        return (
            <div>
                <Lottie
                    options={defaultOptions}
                    height={180}
                    width={180}
                />
            </div>
        );
    }
}

export default LoaderAnimation;