import React, {useEffect, useState} from "react";
import {Menu, Dropdown, Avatar} from "antd";
import {connect} from 'react-redux'
import {
  LogoutOutlined
} from '@ant-design/icons';
import {signOut} from 'redux/actions/Auth';
import {get} from 'lodash';
import utils from "utils";

export const NavProfile = ({signOut, buid}) => {

  const [user, setUser] = useState(utils.getCurrentUser());

  useEffect(()=>{
    if(!buid) {
      setUser(utils.getCurrentUser())
    } else {
      setUser(buid);
    }
  }, [buid])

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="pl-3">
            <h4 className="mb-0">{get(user, "email", "")}</h4>
            <span className="text-muted">{get(user, "first_name", "")}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Cerrar sesión</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={get(user, "profile", "/img/avatars/thumb-1.jpg")}/>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

const mapStateToProps = ({auth}) => {
  const {buid} = auth;
  return {buid}
}
export default connect(mapStateToProps, {signOut})(NavProfile)
