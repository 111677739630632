import React from 'react'
import { APP_NAME, TERMS_AND_CONDITIONS } from 'configs/AppConfig';

export default function Footer() {
	return (
		<footer className="footer">
			<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.</span>
			<div>
				<a className="text-gray" href={TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer">Terminos & condiciones</a>
				<span className="mx-2 text-muted"> | </span>
				<a className="text-gray" href={TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer">Políticas de privacidad</a>
			</div>
		</footer>
	)
}

