import antdEsEs from 'antd/es/locale/es_ES';
import esMsg from "../locales/es_ES.json";

const EsLang = {
  antd: antdEsEs,
  locale: 'es-MX',
  messages: {
    ...esMsg
  },
};
export default EsLang;
