export default {
  apiKey: "AIzaSyC9YHiP92xC3O-VvrGr2fNBj-0mXEuY0Ek",
  authDomain: "guiadehoy-cms.firebaseapp.com",
  projectId: "guiadehoy-cms",
  storageBucket: "guiadehoy-cms.appspot.com",
  messagingSenderId: "400933220385",
  appId: "1:400933220385:web:8cb8092fb110eb4fa9b360",
  measurementId: "G-M7TLSNQXEH"
};

