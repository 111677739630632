import styled from 'styled-components';
export const Content = styled.section`
  position: ${props => (props.position ? props.position : 'fixed')};
  top: 0;
  left: ${props => props.left}px;
  display: none;
  flex-wrap: wrap;
  align-items: center;
  min-height: ${props => (props.mini ? 'auto' : '100vh')};
  width: calc(100vw - ${props => props.left}px);
  height: 100%;
  padding: 0;
  opacity: ${props => props.opacity};
  background: white;
  z-index: 9999;
  .sk-three-bounce {
    margin: 40px auto;
    text-align: center;
  }
  .sk-three-bounce .sk-child {
    width: 20px;
    height: 20px;
    background-color: ${props => props.color};
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  }
  .sk-three-bounce .sk-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .sk-three-bounce .sk-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  @-webkit-keyframes sk-three-bounce {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes sk-three-bounce {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
