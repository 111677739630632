import {SIDE_NAV_LIGHT, NAV_TYPE_SIDE} from 'constants/ThemeConstant';

export const APP_NAME = 'Guía De Hoy';
export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const TERMS_AND_CONDITIONS = "conditions-and-privacy"
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
    "navType": NAV_TYPE_SIDE,
    "sideNavTheme": SIDE_NAV_LIGHT,
    "navCollapsed": false,
    "topNavColor": "#924aca",
    "headerNavColor": "#ffffff",
    "locale": "es",
    "noConnection": false,
}


