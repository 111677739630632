import React from 'react';
import {Provider} from 'react-redux';
import store from './redux/store';
import {BrowserRouter as Router} from 'react-router-dom';
import Views from './views';

import {ConfigProvider} from 'antd';
import esES from 'antd/lib/locale/es_ES';
import Loader from "./components/layout-components/Loader";

function App() {
    return (
        <div className="App">
            <Loader id="loader" style={{zIndex: 9999999, display: 'none'}}/>
            <ConfigProvider locale={esES}>
                <Provider store={store}>
                    <Router>
                        <Views/>
                    </Router>
                </Provider>
            </ConfigProvider>
        </div>
    );
}

export default App;
